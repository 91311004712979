// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.28.1
// source: player.proto
/* eslint-disable */
import _m0 from "protobufjs/minimal";
export const protobufPackage = "taf";
function createBasePlayer() {
    return {};
}
export const Player = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer();
        return message;
    },
};
function createBasePlayer_Create() {
    return {
        host: undefined,
        publicId: undefined,
        appVersion: undefined,
        appId: undefined,
        stepBackwardDuration: undefined,
        stepForwardDuration: undefined,
        muted: undefined,
    };
}
export const Player_Create = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.host !== undefined) {
            writer.uint32(10).string(message.host);
        }
        if (message.publicId !== undefined) {
            writer.uint32(34).string(message.publicId);
        }
        if (message.appVersion !== undefined) {
            writer.uint32(42).string(message.appVersion);
        }
        if (message.appId !== undefined) {
            writer.uint32(48).uint32(message.appId);
        }
        if (message.stepBackwardDuration !== undefined) {
            writer.uint32(61).float(message.stepBackwardDuration);
        }
        if (message.stepForwardDuration !== undefined) {
            writer.uint32(69).float(message.stepForwardDuration);
        }
        if (message.muted !== undefined) {
            writer.uint32(72).bool(message.muted);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_Create();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.host = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.publicId = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.appVersion = reader.string();
                    continue;
                case 6:
                    if (tag !== 48) {
                        break;
                    }
                    message.appId = reader.uint32();
                    continue;
                case 7:
                    if (tag !== 61) {
                        break;
                    }
                    message.stepBackwardDuration = reader.float();
                    continue;
                case 8:
                    if (tag !== 69) {
                        break;
                    }
                    message.stepForwardDuration = reader.float();
                    continue;
                case 9:
                    if (tag !== 72) {
                        break;
                    }
                    message.muted = reader.bool();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            host: isSet(object.host) ? globalThis.String(object.host) : undefined,
            publicId: isSet(object.publicId) ? globalThis.String(object.publicId) : undefined,
            appVersion: isSet(object.appVersion) ? globalThis.String(object.appVersion) : undefined,
            appId: isSet(object.appId) ? globalThis.Number(object.appId) : undefined,
            stepBackwardDuration: isSet(object.stepBackwardDuration)
                ? globalThis.Number(object.stepBackwardDuration)
                : undefined,
            stepForwardDuration: isSet(object.stepForwardDuration)
                ? globalThis.Number(object.stepForwardDuration)
                : undefined,
            muted: isSet(object.muted) ? globalThis.Boolean(object.muted) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.host !== undefined) {
            obj.host = message.host;
        }
        if (message.publicId !== undefined) {
            obj.publicId = message.publicId;
        }
        if (message.appVersion !== undefined) {
            obj.appVersion = message.appVersion;
        }
        if (message.appId !== undefined) {
            obj.appId = Math.round(message.appId);
        }
        if (message.stepBackwardDuration !== undefined) {
            obj.stepBackwardDuration = message.stepBackwardDuration;
        }
        if (message.stepForwardDuration !== undefined) {
            obj.stepForwardDuration = message.stepForwardDuration;
        }
        if (message.muted !== undefined) {
            obj.muted = message.muted;
        }
        return obj;
    },
    create(base) {
        return Player_Create.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_Create();
        message.host = object.host ?? undefined;
        message.publicId = object.publicId ?? undefined;
        message.appVersion = object.appVersion ?? undefined;
        message.appId = object.appId ?? undefined;
        message.stepBackwardDuration = object.stepBackwardDuration ?? undefined;
        message.stepForwardDuration = object.stepForwardDuration ?? undefined;
        message.muted = object.muted ?? undefined;
        return message;
    },
};
function createBasePlayer_PlayLive() {
    return { cid: "", options: undefined };
}
export const Player_PlayLive = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cid !== "") {
            writer.uint32(10).string(message.cid);
        }
        if (message.options !== undefined) {
            Player_PlayOptions.encode(message.options, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_PlayLive();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.cid = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.options = Player_PlayOptions.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            cid: isSet(object.cid) ? globalThis.String(object.cid) : "",
            options: isSet(object.options) ? Player_PlayOptions.fromJSON(object.options) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.cid !== "") {
            obj.cid = message.cid;
        }
        if (message.options !== undefined) {
            obj.options = Player_PlayOptions.toJSON(message.options);
        }
        return obj;
    },
    create(base) {
        return Player_PlayLive.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_PlayLive();
        message.cid = object.cid ?? "";
        message.options = (object.options !== undefined && object.options !== null)
            ? Player_PlayOptions.fromPartial(object.options)
            : undefined;
        return message;
    },
};
function createBasePlayer_PlayVod() {
    return { teasableType: "", termToken: "", teasableId: "", streamId: "", streamProviderId: "", options: undefined };
}
export const Player_PlayVod = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.teasableType !== "") {
            writer.uint32(10).string(message.teasableType);
        }
        if (message.termToken !== "") {
            writer.uint32(18).string(message.termToken);
        }
        if (message.teasableId !== "") {
            writer.uint32(26).string(message.teasableId);
        }
        if (message.streamId !== "") {
            writer.uint32(34).string(message.streamId);
        }
        if (message.streamProviderId !== "") {
            writer.uint32(42).string(message.streamProviderId);
        }
        if (message.options !== undefined) {
            Player_PlayOptions.encode(message.options, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_PlayVod();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.teasableType = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.termToken = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.teasableId = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.streamId = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.streamProviderId = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.options = Player_PlayOptions.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            teasableType: isSet(object.teasableType) ? globalThis.String(object.teasableType) : "",
            termToken: isSet(object.termToken) ? globalThis.String(object.termToken) : "",
            teasableId: isSet(object.teasableId) ? globalThis.String(object.teasableId) : "",
            streamId: isSet(object.streamId) ? globalThis.String(object.streamId) : "",
            streamProviderId: isSet(object.streamProviderId) ? globalThis.String(object.streamProviderId) : "",
            options: isSet(object.options) ? Player_PlayOptions.fromJSON(object.options) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.teasableType !== "") {
            obj.teasableType = message.teasableType;
        }
        if (message.termToken !== "") {
            obj.termToken = message.termToken;
        }
        if (message.teasableId !== "") {
            obj.teasableId = message.teasableId;
        }
        if (message.streamId !== "") {
            obj.streamId = message.streamId;
        }
        if (message.streamProviderId !== "") {
            obj.streamProviderId = message.streamProviderId;
        }
        if (message.options !== undefined) {
            obj.options = Player_PlayOptions.toJSON(message.options);
        }
        return obj;
    },
    create(base) {
        return Player_PlayVod.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_PlayVod();
        message.teasableType = object.teasableType ?? "";
        message.termToken = object.termToken ?? "";
        message.teasableId = object.teasableId ?? "";
        message.streamId = object.streamId ?? "";
        message.streamProviderId = object.streamProviderId ?? "";
        message.options = (object.options !== undefined && object.options !== null)
            ? Player_PlayOptions.fromPartial(object.options)
            : undefined;
        return message;
    },
};
function createBasePlayer_PlayProgram() {
    return { cid: "", programId: 0, options: undefined };
}
export const Player_PlayProgram = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.cid !== "") {
            writer.uint32(10).string(message.cid);
        }
        if (message.programId !== 0) {
            writer.uint32(16).uint32(message.programId);
        }
        if (message.options !== undefined) {
            Player_PlayOptions.encode(message.options, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_PlayProgram();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.cid = reader.string();
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.programId = reader.uint32();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.options = Player_PlayOptions.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            cid: isSet(object.cid) ? globalThis.String(object.cid) : "",
            programId: isSet(object.programId) ? globalThis.Number(object.programId) : 0,
            options: isSet(object.options) ? Player_PlayOptions.fromJSON(object.options) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.cid !== "") {
            obj.cid = message.cid;
        }
        if (message.programId !== 0) {
            obj.programId = Math.round(message.programId);
        }
        if (message.options !== undefined) {
            obj.options = Player_PlayOptions.toJSON(message.options);
        }
        return obj;
    },
    create(base) {
        return Player_PlayProgram.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_PlayProgram();
        message.cid = object.cid ?? "";
        message.programId = object.programId ?? 0;
        message.options = (object.options !== undefined && object.options !== null)
            ? Player_PlayOptions.fromPartial(object.options)
            : undefined;
        return message;
    },
};
function createBasePlayer_PlayRecording() {
    return { id: 0, options: undefined };
}
export const Player_PlayRecording = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== 0) {
            writer.uint32(8).uint32(message.id);
        }
        if (message.options !== undefined) {
            Player_PlayOptions.encode(message.options, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_PlayRecording();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.id = reader.uint32();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.options = Player_PlayOptions.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? globalThis.Number(object.id) : 0,
            options: isSet(object.options) ? Player_PlayOptions.fromJSON(object.options) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== 0) {
            obj.id = Math.round(message.id);
        }
        if (message.options !== undefined) {
            obj.options = Player_PlayOptions.toJSON(message.options);
        }
        return obj;
    },
    create(base) {
        return Player_PlayRecording.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_PlayRecording();
        message.id = object.id ?? 0;
        message.options = (object.options !== undefined && object.options !== null)
            ? Player_PlayOptions.fromPartial(object.options)
            : undefined;
        return message;
    },
};
function createBasePlayer_PlayUrl() {
    return { url: "", licenseUrl: undefined, options: undefined };
}
export const Player_PlayUrl = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.licenseUrl !== undefined) {
            writer.uint32(18).string(message.licenseUrl);
        }
        if (message.options !== undefined) {
            Player_PlayOptions.encode(message.options, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_PlayUrl();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.url = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.licenseUrl = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.options = Player_PlayOptions.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? globalThis.String(object.url) : "",
            licenseUrl: isSet(object.licenseUrl) ? globalThis.String(object.licenseUrl) : undefined,
            options: isSet(object.options) ? Player_PlayOptions.fromJSON(object.options) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.url !== "") {
            obj.url = message.url;
        }
        if (message.licenseUrl !== undefined) {
            obj.licenseUrl = message.licenseUrl;
        }
        if (message.options !== undefined) {
            obj.options = Player_PlayOptions.toJSON(message.options);
        }
        return obj;
    },
    create(base) {
        return Player_PlayUrl.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_PlayUrl();
        message.url = object.url ?? "";
        message.licenseUrl = object.licenseUrl ?? undefined;
        message.options = (object.options !== undefined && object.options !== null)
            ? Player_PlayOptions.fromPartial(object.options)
            : undefined;
        return message;
    },
};
function createBasePlayer_GetAvailableAudioTracks() {
    return {};
}
export const Player_GetAvailableAudioTracks = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_GetAvailableAudioTracks();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player_GetAvailableAudioTracks.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer_GetAvailableAudioTracks();
        return message;
    },
};
function createBasePlayer_SetAudioTrack() {
    return { track: undefined };
}
export const Player_SetAudioTrack = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_SetAudioTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return Player_SetAudioTrack.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_SetAudioTrack();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayer_GetSelectedAudioTrack() {
    return {};
}
export const Player_GetSelectedAudioTrack = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_GetSelectedAudioTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player_GetSelectedAudioTrack.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer_GetSelectedAudioTrack();
        return message;
    },
};
function createBasePlayer_GetAvailableSubtitlesTracks() {
    return {};
}
export const Player_GetAvailableSubtitlesTracks = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_GetAvailableSubtitlesTracks();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player_GetAvailableSubtitlesTracks.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer_GetAvailableSubtitlesTracks();
        return message;
    },
};
function createBasePlayer_SetSubtitlesTrack() {
    return { track: undefined };
}
export const Player_SetSubtitlesTrack = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.track !== undefined) {
            Player_MediaTrack.encode(message.track, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_SetSubtitlesTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.track = Player_MediaTrack.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { track: isSet(object.track) ? Player_MediaTrack.fromJSON(object.track) : undefined };
    },
    toJSON(message) {
        const obj = {};
        if (message.track !== undefined) {
            obj.track = Player_MediaTrack.toJSON(message.track);
        }
        return obj;
    },
    create(base) {
        return Player_SetSubtitlesTrack.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_SetSubtitlesTrack();
        message.track = (object.track !== undefined && object.track !== null)
            ? Player_MediaTrack.fromPartial(object.track)
            : undefined;
        return message;
    },
};
function createBasePlayer_GetSelectedSubtitlesTrack() {
    return {};
}
export const Player_GetSelectedSubtitlesTrack = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_GetSelectedSubtitlesTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player_GetSelectedSubtitlesTrack.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer_GetSelectedSubtitlesTrack();
        return message;
    },
};
function createBasePlayer_Play() {
    return {};
}
export const Player_Play = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_Play();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player_Play.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer_Play();
        return message;
    },
};
function createBasePlayer_Pause() {
    return {};
}
export const Player_Pause = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_Pause();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player_Pause.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer_Pause();
        return message;
    },
};
function createBasePlayer_Seek() {
    return { time: 0 };
}
export const Player_Seek = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.time !== 0) {
            writer.uint32(13).float(message.time);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_Seek();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 13) {
                        break;
                    }
                    message.time = reader.float();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { time: isSet(object.time) ? globalThis.Number(object.time) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.time !== 0) {
            obj.time = message.time;
        }
        return obj;
    },
    create(base) {
        return Player_Seek.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_Seek();
        message.time = object.time ?? 0;
        return message;
    },
};
function createBasePlayer_SeekForward() {
    return { steps: 0 };
}
export const Player_SeekForward = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.steps !== 0) {
            writer.uint32(8).uint32(message.steps);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_SeekForward();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.steps = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { steps: isSet(object.steps) ? globalThis.Number(object.steps) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.steps !== 0) {
            obj.steps = Math.round(message.steps);
        }
        return obj;
    },
    create(base) {
        return Player_SeekForward.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_SeekForward();
        message.steps = object.steps ?? 0;
        return message;
    },
};
function createBasePlayer_SeekBackward() {
    return { steps: 0 };
}
export const Player_SeekBackward = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.steps !== 0) {
            writer.uint32(8).uint32(message.steps);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_SeekBackward();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.steps = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { steps: isSet(object.steps) ? globalThis.Number(object.steps) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.steps !== 0) {
            obj.steps = Math.round(message.steps);
        }
        return obj;
    },
    create(base) {
        return Player_SeekBackward.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_SeekBackward();
        message.steps = object.steps ?? 0;
        return message;
    },
};
function createBasePlayer_SetVolume() {
    return { volume: 0 };
}
export const Player_SetVolume = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.volume !== 0) {
            writer.uint32(13).float(message.volume);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_SetVolume();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 13) {
                        break;
                    }
                    message.volume = reader.float();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { volume: isSet(object.volume) ? globalThis.Number(object.volume) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.volume !== 0) {
            obj.volume = message.volume;
        }
        return obj;
    },
    create(base) {
        return Player_SetVolume.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_SetVolume();
        message.volume = object.volume ?? 0;
        return message;
    },
};
function createBasePlayer_SetRate() {
    return { rate: 0 };
}
export const Player_SetRate = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.rate !== 0) {
            writer.uint32(13).float(message.rate);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_SetRate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 13) {
                        break;
                    }
                    message.rate = reader.float();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { rate: isSet(object.rate) ? globalThis.Number(object.rate) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.rate !== 0) {
            obj.rate = message.rate;
        }
        return obj;
    },
    create(base) {
        return Player_SetRate.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_SetRate();
        message.rate = object.rate ?? 0;
        return message;
    },
};
function createBasePlayer_Stop() {
    return {};
}
export const Player_Stop = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_Stop();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player_Stop.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer_Stop();
        return message;
    },
};
function createBasePlayer_Destroy() {
    return {};
}
export const Player_Destroy = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_Destroy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return Player_Destroy.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBasePlayer_Destroy();
        return message;
    },
};
function createBasePlayer_PlayOptions() {
    return {
        pin: undefined,
        preferredAudioTrack: undefined,
        preferredSubtitlesTrack: undefined,
        startupPosition: undefined,
        drmRequired: false,
        adsUrl: undefined,
        fairPlayCertificateUrl: undefined,
    };
}
export const Player_PlayOptions = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.pin !== undefined) {
            writer.uint32(10).string(message.pin);
        }
        if (message.preferredAudioTrack !== undefined) {
            writer.uint32(18).string(message.preferredAudioTrack);
        }
        if (message.preferredSubtitlesTrack !== undefined) {
            writer.uint32(26).string(message.preferredSubtitlesTrack);
        }
        if (message.startupPosition !== undefined) {
            writer.uint32(33).double(message.startupPosition);
        }
        if (message.drmRequired !== false) {
            writer.uint32(40).bool(message.drmRequired);
        }
        if (message.adsUrl !== undefined) {
            writer.uint32(50).string(message.adsUrl);
        }
        if (message.fairPlayCertificateUrl !== undefined) {
            writer.uint32(58).string(message.fairPlayCertificateUrl);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_PlayOptions();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.pin = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.preferredAudioTrack = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.preferredSubtitlesTrack = reader.string();
                    continue;
                case 4:
                    if (tag !== 33) {
                        break;
                    }
                    message.startupPosition = reader.double();
                    continue;
                case 5:
                    if (tag !== 40) {
                        break;
                    }
                    message.drmRequired = reader.bool();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.adsUrl = reader.string();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.fairPlayCertificateUrl = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            pin: isSet(object.pin) ? globalThis.String(object.pin) : undefined,
            preferredAudioTrack: isSet(object.preferredAudioTrack)
                ? globalThis.String(object.preferredAudioTrack)
                : undefined,
            preferredSubtitlesTrack: isSet(object.preferredSubtitlesTrack)
                ? globalThis.String(object.preferredSubtitlesTrack)
                : undefined,
            startupPosition: isSet(object.startupPosition) ? globalThis.Number(object.startupPosition) : undefined,
            drmRequired: isSet(object.drmRequired) ? globalThis.Boolean(object.drmRequired) : false,
            adsUrl: isSet(object.adsUrl) ? globalThis.String(object.adsUrl) : undefined,
            fairPlayCertificateUrl: isSet(object.fairPlayCertificateUrl)
                ? globalThis.String(object.fairPlayCertificateUrl)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.pin !== undefined) {
            obj.pin = message.pin;
        }
        if (message.preferredAudioTrack !== undefined) {
            obj.preferredAudioTrack = message.preferredAudioTrack;
        }
        if (message.preferredSubtitlesTrack !== undefined) {
            obj.preferredSubtitlesTrack = message.preferredSubtitlesTrack;
        }
        if (message.startupPosition !== undefined) {
            obj.startupPosition = message.startupPosition;
        }
        if (message.drmRequired !== false) {
            obj.drmRequired = message.drmRequired;
        }
        if (message.adsUrl !== undefined) {
            obj.adsUrl = message.adsUrl;
        }
        if (message.fairPlayCertificateUrl !== undefined) {
            obj.fairPlayCertificateUrl = message.fairPlayCertificateUrl;
        }
        return obj;
    },
    create(base) {
        return Player_PlayOptions.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_PlayOptions();
        message.pin = object.pin ?? undefined;
        message.preferredAudioTrack = object.preferredAudioTrack ?? undefined;
        message.preferredSubtitlesTrack = object.preferredSubtitlesTrack ?? undefined;
        message.startupPosition = object.startupPosition ?? undefined;
        message.drmRequired = object.drmRequired ?? false;
        message.adsUrl = object.adsUrl ?? undefined;
        message.fairPlayCertificateUrl = object.fairPlayCertificateUrl ?? undefined;
        return message;
    },
};
function createBasePlayer_MediaTrack() {
    return { id: "", locale: "", codec: undefined };
}
export const Player_MediaTrack = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.locale !== "") {
            writer.uint32(18).string(message.locale);
        }
        if (message.codec !== undefined) {
            writer.uint32(26).string(message.codec);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayer_MediaTrack();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.id = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.locale = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.codec = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? globalThis.String(object.id) : "",
            locale: isSet(object.locale) ? globalThis.String(object.locale) : "",
            codec: isSet(object.codec) ? globalThis.String(object.codec) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.id !== "") {
            obj.id = message.id;
        }
        if (message.locale !== "") {
            obj.locale = message.locale;
        }
        if (message.codec !== undefined) {
            obj.codec = message.codec;
        }
        return obj;
    },
    create(base) {
        return Player_MediaTrack.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBasePlayer_MediaTrack();
        message.id = object.id ?? "";
        message.locale = object.locale ?? "";
        message.codec = object.codec ?? undefined;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
