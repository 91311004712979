import { errorPlayerNotCreated } from './utils';
export const playerSeek = (player, position) => {
    if (!player) {
        throw errorPlayerNotCreated;
    }
    player.seek(position.time);
};
export const playerSeekForward = (player, seekForward) => {
    if (!player) {
        throw errorPlayerNotCreated;
    }
    player.seekForward(seekForward.steps);
};
export const playerSeekBackward = (player, seekBackward) => {
    if (!player) {
        throw errorPlayerNotCreated;
    }
    player.seekBackward(seekBackward.steps);
};
