// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.28.1
// source: taf_action.proto
/* eslint-disable */
import _m0 from "protobufjs/minimal";
export const protobufPackage = "taf";
function createBaseTafAction() {
    return {};
}
export const TafAction = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return TafAction.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseTafAction();
        return message;
    },
};
function createBaseTafAction_Sleep() {
    return { ms: 0 };
}
export const TafAction_Sleep = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.ms !== 0) {
            writer.uint32(8).uint32(message.ms);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_Sleep();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.ms = reader.uint32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { ms: isSet(object.ms) ? globalThis.Number(object.ms) : 0 };
    },
    toJSON(message) {
        const obj = {};
        if (message.ms !== 0) {
            obj.ms = Math.round(message.ms);
        }
        return obj;
    },
    create(base) {
        return TafAction_Sleep.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_Sleep();
        message.ms = object.ms ?? 0;
        return message;
    },
};
function createBaseTafAction_SetupZapiProxy() {
    return { host: "" };
}
export const TafAction_SetupZapiProxy = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.host !== "") {
            writer.uint32(10).string(message.host);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_SetupZapiProxy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.host = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { host: isSet(object.host) ? globalThis.String(object.host) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.host !== "") {
            obj.host = message.host;
        }
        return obj;
    },
    create(base) {
        return TafAction_SetupZapiProxy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_SetupZapiProxy();
        message.host = object.host ?? "";
        return message;
    },
};
function createBaseTafAction_SetupZapiMockedProxy() {
    return { streamapiConfig: undefined };
}
export const TafAction_SetupZapiMockedProxy = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.streamapiConfig !== undefined) {
            TafAction_SetupZapiMockedProxy_Config.encode(message.streamapiConfig, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_SetupZapiMockedProxy();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.streamapiConfig = TafAction_SetupZapiMockedProxy_Config.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            streamapiConfig: isSet(object.streamapiConfig)
                ? TafAction_SetupZapiMockedProxy_Config.fromJSON(object.streamapiConfig)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.streamapiConfig !== undefined) {
            obj.streamapiConfig = TafAction_SetupZapiMockedProxy_Config.toJSON(message.streamapiConfig);
        }
        return obj;
    },
    create(base) {
        return TafAction_SetupZapiMockedProxy.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_SetupZapiMockedProxy();
        message.streamapiConfig = (object.streamapiConfig !== undefined && object.streamapiConfig !== null)
            ? TafAction_SetupZapiMockedProxy_Config.fromPartial(object.streamapiConfig)
            : undefined;
        return message;
    },
};
function createBaseTafAction_SetupZapiMockedProxy_Config() {
    return { url: "", partnerId: "", secret: "" };
}
export const TafAction_SetupZapiMockedProxy_Config = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.partnerId !== "") {
            writer.uint32(18).string(message.partnerId);
        }
        if (message.secret !== "") {
            writer.uint32(26).string(message.secret);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_SetupZapiMockedProxy_Config();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.url = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.partnerId = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.secret = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return {
            url: isSet(object.url) ? globalThis.String(object.url) : "",
            partnerId: isSet(object.partnerId) ? globalThis.String(object.partnerId) : "",
            secret: isSet(object.secret) ? globalThis.String(object.secret) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.url !== "") {
            obj.url = message.url;
        }
        if (message.partnerId !== "") {
            obj.partnerId = message.partnerId;
        }
        if (message.secret !== "") {
            obj.secret = message.secret;
        }
        return obj;
    },
    create(base) {
        return TafAction_SetupZapiMockedProxy_Config.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_SetupZapiMockedProxy_Config();
        message.url = object.url ?? "";
        message.partnerId = object.partnerId ?? "";
        message.secret = object.secret ?? "";
        return message;
    },
};
function createBaseTafAction_StartSession() {
    return { name: "" };
}
export const TafAction_StartSession = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_StartSession();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(object) {
        return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
    },
    toJSON(message) {
        const obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        return obj;
    },
    create(base) {
        return TafAction_StartSession.fromPartial(base ?? {});
    },
    fromPartial(object) {
        const message = createBaseTafAction_StartSession();
        message.name = object.name ?? "";
        return message;
    },
};
function createBaseTafAction_CleanupSession() {
    return {};
}
export const TafAction_CleanupSession = {
    encode(_, writer = _m0.Writer.create()) {
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTafAction_CleanupSession();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON(_) {
        return {};
    },
    toJSON(_) {
        const obj = {};
        return obj;
    },
    create(base) {
        return TafAction_CleanupSession.fromPartial(base ?? {});
    },
    fromPartial(_) {
        const message = createBaseTafAction_CleanupSession();
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
